import type { AllIconKeys } from "@biom3/react";

export type ERC20Address = `0x${string}` | "native";

export type ChainAddress = `0x${string}`;

export type Network = "starkEx" | "zkEvm";

export type Chain = "imtbl-x" | "imtbl-zkevm" | "eth";

export type TokenID = string;

export type Cursor = string | undefined;

export type URL = string;

export type TokenType = "ERC721" | "ERC1155";

export type WalletTypeKey = "passport" | "external";

export enum WalletTypes {
  METAMASK = "io.metamask",
  COINBASE = "com.coinbase.wallet",
  WALLET_CONNECT = "walletconnect",
  PASSPORT = "passport",
  PASSPORT_RDNS = "com.immutable.passport",
  OKX = "com.okex.wallet",
  INJECTED = "injected",
}

export type Collection = {
  network: Network;
  id: string;
  name: string;
  symbol?: string;
  address: ChainAddress;
  description?: string;
  image?: string;
  icon?: string;
  balance: number;
  owners?: string[];
  hasImportableAssets: boolean;
  tokenType: TokenType;
};

export type CollectionsResponse = {
  collections: Collection[];
  cursor: Cursor;
};

export type CoinBalance = {
  network: Network;
  name?: string;
  symbol: string;
  contractAddress: ERC20Address | "";
  balance: string;
  wallet: string;
  walletType: WalletTypes;
  imageUrl?: URL;
  decimals: number;
};

export type Item = {
  token_type: TokenType;
  network: Network;
  name: string;
  animation_url: URL;
  description: string;
  token_id: TokenID;
  image: string;
  owner: ChainAddress;
  balance: string;
};

export type ItemV2 = {
  token_type: TokenType;
  network: Network;
  name: string;
  animation_url: URL;
  description: string;
  token_id: TokenID;
  image: string;
  owner: ChainAddress;
  balance: string;
  acquired_at: Date;
  market_data: ItemMarketData;
  metadata: Metadata;
  bids: Bid[];
};

export type Page = {
  next_cursor?: string;
  previous_cursor?: string;
};

export type Metadata = {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  [key: string]: any;
};

type CommonItemDetails = {
  collectionName?: string;
  collection: ChainAddress;
  owner?: ChainAddress;
  name?: string;
  description?: string;
  image?: string;
  animationUrl?: string;
  metadata?: Metadata;
  createdAt?: Date;
  updatedAt?: Date;
  tokenId: TokenID;
  balance: string;
  tokenType: TokenType;
};

type StarkExItem = CommonItemDetails & {
  discriminator: "STARK_EX";
  network: "starkEx";
  status:
    | "eth"
    | "imx"
    | "preparing_withdrawal"
    | "withdrawable"
    | "burned"
    | undefined;
};

type ZkEvmItem = CommonItemDetails & {
  discriminator: "ZKEVM_ITEM";
  network: "zkEvm";
};

export type PricingToken = {
  type: "ERC20" | "NATIVE";
  symbol: string;
  contract_address: ERC20Address;
  decimals: number;
};

export type PricingData = {
  token: PricingToken;
  amount: string;
};

type Sale = {
  date: string;
  pricing_data: PricingData;
};

type ItemMarketData = {
  floor_listing?: PricingData;
  current_listing?: PricingData;
  last_sold?: Sale;
};

type FeeType = "MAKER_ECOSYSTEM" | "TAKER_ECOSYSTEM" | "PROTOCOL" | "ROYALTY";

type BidFee = {
  amount: string;
  recipient_address: string;
  type: FeeType;
};

export type Bid = {
  id: string;
  amount: string;
  token_address: string;
  fees: BidFee[];
  unit_price: string;
  available_quantity: string;
};

export type ZkEvmItemV2 = CommonItemDetails & {
  discriminator: "ZKEVM_ITEM_V2";
  network: "zkEvm";
  acquiredAt?: Date;
  marketData?: ItemMarketData;
  bids?: Bid[];
};

export type ItemDetails = StarkExItem | ZkEvmItem | ZkEvmItemV2;

export type ItemData = {
  nextCursor?: string;
  items: ItemDetails[];
};

export type Wallets = {
  type: string;
  address: string;
  name: string;
};

export enum EnvironmentNames {
  DEV = "dev",
  SANDBOX = "sandbox",
  PRODUCTION = "production",
}

export type PassportConfig = {
  audience: "platform_api";
  clientId: string;
  logoutMode?: "redirect" | "silent";
  logoutRedirectUri: string;
  redirectUri: URL;
  scope: string;
  forceScwDeployBeforeMessageSignature?: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  overrides?: any;
};

export type Wallet = {
  address: ChainAddress;
  type: WalletTypes;
  userId?: string;
  name?: string;
};

export type ItemOption = {
  label: string;
  sortDirection: string;
  sortBy: string;
  key: string;
};

export type ItemSortOptions = {
  [key: string]: ItemOption;
};

export type SubscriptionStatus = "opted_in" | "subscribed" | "unsubscribed";

export type HideActivationBadges = {
  balances: boolean;
  inventory: boolean;
  external_wallets: boolean;
};

export type ProfilePreferences = {
  reveal_address: boolean;
  marketing_consent: SubscriptionStatus;
  onboard: boolean;
  hide_activation_badges: HideActivationBadges;
};

type PartialProfilePreferenceKeys<T, K extends keyof T> = Partial<
  Record<K, T[K]>
>;

export type PartialProfilePrefences = PartialProfilePreferenceKeys<
  ProfilePreferences,
  keyof ProfilePreferences
>;

export type ActionItem = {
  key: string;
  icon: AllIconKeys;
  url?: URL;
  trackingKey?: string;
};

export type Marketplace = {
  name: string;
  url: URL;
  caption: string;
  imageUrl: URL;
};

type LinkWalletErrorCodeUserRejected = 4001;

export type LinkWalletModal = JSX.Element;

export type LinkWalletErrorCode =
  | "MAX_WALLETS_LINKED"
  | "ALREADY_LINKED"
  | "UNAUTHORISED"
  | "CONNECT_FAILED"
  | "SIGNATURE_UNVERIFIED"
  | "UNKNOWN"
  | "NETWORK_FAILED"
  | LinkWalletErrorCodeUserRejected;

export type LinkWalletState =
  | "LINKING"
  | "WAITING"
  | "SUCCESS"
  | "IMPORT"
  | "EMPTY_WALLET"
  | undefined;

export type LinkWalletError = {
  stack?: string | LinkWalletErrorCode;
  code: LinkWalletErrorCode;
};

export type LinkWalletCallback = false | (() => void);

export const CONNECTOR_NAME_MAP: { [key: string]: string } = {
  walletConnect: "Other wallets",
};

export type WalletGemsResponse = {
  message: string;
  code: number;
  result: {
    gems: number;
    wallet_address: string;
  };
};

export type WalletGems = {
  gems: number;
  walletAddress: string;
};

export type Erc20Token = {
  decimals?: number;
  symbol?: string;
  name?: string;
  image_url?: string;
  contract_address: string;
};

export type MainnetBalance = {
  address: string;
  symbol: string;
  tokenName: string;
  balance: string;
  decimals: number;
  tokenImage?: string;
};

export enum GetSSRPropsError {
  InvalidUid = "Invalid uid",
  PageNotFound = "Page not found",
  GameNotFound = "Game not found",
}

export type ListingDisplayInfo = {
  askingPrice: string;
  fiatPrice: string;
};

export type Listing = ListingDisplayInfo & {
  id: string;
};

export type PricingDisplayInfo = {
  floorPrice: string;
  lastSoldDate?: string;
  lastSoldPrice?: string;
};

export type BestOffer = {
  id: string;
  offeredPrice: string;
  fiatPrice: string;
  netPrice: string;
  availableQuantity?: number;
};

import type { passport } from "@imtbl/sdk";
import { type LDFlagSet, useLDClient } from "launchdarkly-react-client-sdk";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAnalytics } from "./AnalyticsProvider";
import { usePassportProvider } from "./PassportProvider";

export type LdContextTypes = "browser" | "wallet" | "user";

type FeatureFlags = {
  currentContexts: LdContextTypes[];
  identify: (walletAddress?: string) => void;
  enableAddTokensWidget?: boolean;
  chessuniverseWishlistEnabled?: string;
  addTokensPreselectedToken?: string;
};

export const FeatureFlagContext = createContext<FeatureFlags>({
  currentContexts: [],
  identify: () => {},
});

const useFeatureFlag = () => {
  const ctx = useContext(FeatureFlagContext);
  if (!ctx) {
    throw new Error("useFeatureFlag must be used within a FeatureFlagProvider");
  }
  return ctx;
};

// biome-ignore lint/suspicious/noExplicitAny: Specifically casting from any to T
const safeCast = <T,>(value: any): T | undefined => {
  if (value !== undefined) {
    return value as T;
  }
  return undefined;
};

const getKnownFlags = (flags: LDFlagSet): FeatureFlags => {
  return {
    currentContexts: [],
    identify: () => {},
    enableAddTokensWidget: safeCast(flags["enable-add-tokens-widget"]),
    chessuniverseWishlistEnabled: safeCast(
      flags["activation-experiment-chessuniverse-wishlist"],
    ),
    addTokensPreselectedToken: safeCast(flags["add-tokens-preselected-token"]),
  };
};

type LdMultiContext = {
  kind: "multi";
  browser?: {
    key: string;
  };
  wallet?: {
    key: string;
  };
  user?: {
    key: string;
    name?: string;
    email?: string;
  };
};

const getLaunchDarklyContext = (
  anonId?: string,
  walletAddress?: string,
  userProfile?: passport.UserProfile,
) => {
  const contextTypes: LdContextTypes[] = [];
  const multiContext: LdMultiContext = {
    kind: "multi",
  };

  if (anonId) {
    multiContext.browser = { key: anonId };
    contextTypes.push("browser");
  }

  if (walletAddress) {
    multiContext.wallet = { key: walletAddress };
    contextTypes.push("wallet");
  }

  if (userProfile) {
    multiContext.user = {
      key: userProfile.sub,
      name: userProfile.nickname,
      email: userProfile.email,
    };
    contextTypes.push("user");
  }

  return { multiContext, contextTypes };
};

/**
 * This provider is purpose-built for experiments and ensuring they are correctly tracked.
 *
 * We have 3 different types of contexts:
 * - browser: This is the device context aka anonymous user
 * - wallet: User signed into Quests with a wallet
 * - user: User signed in with Passport
 *
 * When using LaunchDarkly hooks directly they will often return an initial value that is different to what the identified context has evaluated to.
 * E.g. const { newUserExperiment } = useFlags(); // 1st evaluation == "A", 2nd evaluation == "B"
 * This will break the integrity of the data when tracking experiments, so they must be tracked with the value returned from the identify call.
 *
 * When creating flags in LaunchDarkly make sure you are using the correct context and key for the rollout.
 * - browser: Use this for experiments that include logged out and logged in flows. E.g. Quests, Games pages
 * - user: Use this for experiments that only apply to logged in users. E.g. Inventory, Balances
 */
const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const { userInfo } = usePassportProvider();
  const ldClient = useLDClient();
  const [flags, setFlags] = useState<FeatureFlags | undefined>();
  const [anonId, setAnonId] = useState<string | undefined>();
  useEffect(() => {
    if (ldClient) {
      const getAnonId = async () => {
        await ldClient.waitForInitialization();
        const context = ldClient.getContext();
        if (context.anonymous && typeof context.key === "string") {
          setAnonId(context.key);
        }
      };
      getAnonId();
    }
  }, [ldClient]);

  const identify = useCallback(
    async (walletAddress?: string) => {
      if (!ldClient) {
        return;
      }
      const { multiContext, contextTypes } = getLaunchDarklyContext(
        anonId,
        walletAddress,
        userInfo,
      );

      // Need at least one context type to identify with
      if (contextTypes.length === 0) {
        return;
      }

      const flagSet = await ldClient.identify(multiContext);
      const knownFlags = getKnownFlags(flagSet);
      setFlags({ ...knownFlags, currentContexts: contextTypes });
    },
    [ldClient, anonId, userInfo],
  );

  // Trigger identify when there are context changes (anonId && userInfo)
  useEffect(() => {
    identify(undefined);
  }, [identify]);

  return (
    <FeatureFlagContext.Provider
      value={{
        ...flags,
        identify,
        currentContexts: flags?.currentContexts ?? [],
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useTrackExperiment = (
  experimentName: string,
  variant: string | undefined,
  expectedContext: LdContextTypes,
) => {
  const [tracked, setTracked] = useState(false);
  const { currentContexts } = useFeatureFlag();
  const { track } = useAnalytics();

  // This prevents users showing up in multiple variants by ensuring that the user has been identified with the correct context before tracking it.
  if (!tracked && variant && currentContexts.includes(expectedContext)) {
    track({
      screen: "Test",
      userJourney: "Split",
      control: "Experiment",
      controlType: "Group",
      extras: {
        id: experimentName,
        variant,
      },
    });
    setTracked(true);
  }
};

export { FeatureFlagProvider, useFeatureFlag, useTrackExperiment };

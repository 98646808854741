import {
  type StandardAnalyticsActions,
  type StandardAnalyticsControlTypes,
  createAnalytics,
} from "@imtbl/react-analytics";

import { appConfig } from "@/constants";

export type DashboardAnalyticsJourneyType =
  | "Page"
  | "Profile"
  | "Activity"
  | "Balances"
  | "Collections"
  | "Games"
  | "Wallets"
  | "AssetTransfer"
  | "Rewards"
  | "Onboarding"
  | "Gems"
  | "Split"
  | "Listing"
  | "Bid"
  | "Connect"
  | "Disconnect"
  | "Claim"
  | "Ecosystem"
  | "Quest"
  | UserJourneyQuest
  | QuestsNavigationJourney
  | WishlistUserJourney;

export type UserJourneyQuest =
  | "TombOfHeroesQuest"
  | "TradeZkEvmBonus"
  | "TradeZkEvmIMXBonus"
  | "IlluviumBonus"
  | "BabySharkBonus"
  | "HuntersOnChainBonus"
  | "MedievalEmpiresBonus"
  | "MedievalEmpiresLevel8Bonus"
  | "SpaceNationWhitelistSpots"
  | "SpaceNationLayer3Quest"
  | "CallOfTheVoydRaffle"
  | "SetUpPassportQuest"
  | "PoolMastersQuest"
  | "TreeverseQuest"
  | "CounterFireQuest"
  | "RavenQuestQuest"
  | "SomnisBonus"
  | "GoGPromoTileJadey"
  | "PoolMastersManualQuest"
  | "ParadiseTycoonQuest"
  | "ImmortalRisingQuest"
  | "RebelBotsQuest"
  | "RavenQuestPromo"
  | "RavenQuestInGame"
  | "SomnisRumbleRushPromo";

export type WishlistUserJourney = "BadMadRobots";

type QuestsNavigationJourney =
  | "FeaturedQuests"
  | "MainQuests"
  | "SideQuests"
  | "PromoTiles";

export type Component =
  | "ConnectionProvider"
  | "Claim"
  | "Dashboard"
  | "GameShowcase"
  | "QuestTile"
  | "QuestTileModal"
  | "HowItWorksModal"
  | "QuestsNavigation"
  | "PromoTile"
  | "RecommendedQuests"
  | "WishlistTile";

export type DashboardAnalyticsScreenType = string;
export type DashboardAnalyticsControlType = string;

export type DashboardAnalyticsBaseType = {
  userJourney: DashboardAnalyticsJourneyType;
  screen: DashboardAnalyticsScreenType;
};

export const { AnalyticsProvider, useAnalytics } = createAnalytics<
  DashboardAnalyticsJourneyType,
  DashboardAnalyticsScreenType,
  DashboardAnalyticsControlType,
  StandardAnalyticsControlTypes | "Effect" | "Card" | "Modal" | "Group",
  StandardAnalyticsActions | "Changed" | "Closed" | "Scrolled"
>({
  writeKey: appConfig.SEGMENT_KEY,
  appName: "PassportDashboard",
});

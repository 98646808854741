import { appConfigFn } from "@/constants/appConfig";

export const SIDEBAR_WIDTH = "220px";
export const HOMEPAGE = "/quests";
export const MODAL_WIDTH = "430px";
export const HERO_GRID_MIN_COLUMN_WIDTH = "330px";

export const appConfig = appConfigFn();
// @NOTE: use this when we have no valid image url to pass to Biome Image
// components, as this will have them display default imagery
export const BAD_IMAGE_URL = "https://something";
export const PINATA_GATEWAY_URL = "https://gateway.pinata.cloud";

export const TOOLKIT_URL =
  appConfig.ENVIRONMENT === "production"
    ? "https://toolkit.immutable.com"
    : "https://checkout-playground.sandbox.immutable.com";

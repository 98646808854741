"use client";
import type { NextPageContext } from "next";

import { notifyError } from "@/utils/monitoring";
import { onDarkBase } from "@biom3/design-tokens";
import {
  BiomeCombinedProviders,
  Body,
  Box,
  CloudImage,
  Heading,
  Stack,
  centerFlexChildren,
  vFlex,
} from "@biom3/react";
import { useEffect } from "react";

type ErrorComponentProps = {
  error: Error;
  statusCode?: number;
};

function ErrorComponent({ error, statusCode }: ErrorComponentProps) {
  useEffect(() => {
    notifyError(error, "appError", { statusCode });
    console.error(error, { statusCode });
  }, [error, statusCode]);
  return (
    <BiomeCombinedProviders theme={{ base: onDarkBase }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ h: "100dvh" }}
        gap="base.spacing.x10"
      >
        <CloudImage
          use={<img src="/errorState.svg" alt="error state" />}
          sx={{ w: "400px" }}
        />
        <Box
          rc={<hgroup />}
          sx={{ ...vFlex, ...centerFlexChildren, gap: "base.spacing.x2" }}
        >
          <Heading sx={{ c: "base.color.status.fatal.bright" }} size="xLarge">
            Oh Snap!{" "}
          </Heading>
          {/* biome-ignore lint/a11y/useHeadingContent: <explanation> */}
          <Heading rc={<h3 />}>
            We've hit a glitch{" "}
            {statusCode !== undefined ? `(${statusCode})` : ""} - we're onto it
          </Heading>
        </Box>
        <Body>Please try again later</Body>
      </Stack>
    </BiomeCombinedProviders>
  );
}

ErrorComponent.getInitialProps = async ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { error: err, statusCode };
};

export default ErrorComponent;

import { appConfig } from "@/constants";
import { type ReactNode, createContext, useContext } from "react";
import useSWR, { type SWRConfiguration } from "swr";
import { usePassportProvider } from "./PassportProvider";
export interface Earnings {
  type: string;
  rule_id: string;
  total: number;
  increase: number;
  timestamp: string;
}

export interface EarningsResponse {
  result: { latest_earnings: Earnings[] };
  code: number;
  message: string;
}

export interface EarningsContextValue {
  earnings: Earnings[] | undefined;
  isLoading: boolean;
  error: Error | undefined;
  mutate: () => Promise<EarningsResponse | undefined>;
}

const EarningsContext = createContext<EarningsContextValue | undefined>(
  undefined,
);

const fetcher = async (url: string): Promise<EarningsResponse> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

const config: SWRConfiguration = {
  revalidateOnFocus: false,
};

export const EarningsProvider = ({ children }: { children: ReactNode }) => {
  const { walletAddress } = usePassportProvider();
  const { data, error, isLoading, mutate } = useSWR<EarningsResponse>(
    walletAddress &&
      `${appConfig.IMMUTABLE_API_URL}/v1/rewards/gems/${walletAddress}/latest_earnings`,
    fetcher,
    config,
  );

  const earnings = data?.result?.latest_earnings.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  );

  const value = {
    earnings,
    isLoading,
    error,
    mutate,
  };

  return (
    <EarningsContext.Provider value={value}>
      {children}
    </EarningsContext.Provider>
  );
};

export const useEarnings = () => {
  const ctx = useContext(EarningsContext);
  if (ctx === undefined) {
    throw new Error(
      "useOnboardingQuests must be used within an LatestEarningsProvider",
    );
  }
  return ctx;
};
